.snippet {
  width: 30%;
  min-width: 230px;
  margin-bottom: 1rem;
}
.total-sum {
  display: flex;
}
.total-sum__number {
  display: inline-block;
}
.total-sum__title {
  display: inline-block;
  margin-right: auto;
}

.category-sum__title {
  margin-bottom: 0.375rem;
}
.category-sum__item {
  display: flex;
  align-items: center;
}
.category-sum__category {
  margin: 0.25rem 0;
  margin-right: auto;
}
