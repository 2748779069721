.footer {
  background-color: var(--color-accent);
  color: var(--color-light);
  font-size: 0.7rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.4rem;
  text-align: center;
}
.footer__text {
  margin: 0;
}
.footer__link {
  text-decoration: underline;
  color: var(--color-light)
}