.list-item--expense {
  display: grid;
  grid-template-columns: 3fr 5rem 2fr auto 5rem;
  gap: 1rem;
}

.expense__datafield {
  margin: 0.5rem 0;
}

.expense__buttonfield {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .list-item--expense {
    grid-template-columns: 3fr auto 5rem;
    grid-template-rows: auto auto;
    row-gap: 0.3rem;
  }
  .expense__datafield {
    margin-bottom: 0;
    margin-top: 0.75rem;
  }
  .expense__datafield:nth-of-type(3),
  .expense__datafield:nth-of-type(4) {
    margin-top: 0;
    margin-bottom: 0.75rem;
  }
  .expense__buttonfield {
    grid-row: 1 / span 2;
    grid-column: 3;
    
  }
  .button-icon {
    display: inline-block;
  }
}