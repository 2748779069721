.list--category {
  min-width: 230px;
  max-width: 500px;  
}

.list-item--category {
  list-style: none;
  display: flex;
  align-items: center;
  width: 100%;
}

.category-list__name {
  font-weight: normal;
  display: inline-block;
  margin: 0.5rem 0;
  margin-right: auto;
}
