.navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  gap: 0.5rem;
}
.navbar__menu {
  font-size: 1.2rem;
  display: flex;
}

.navbar__logo {
  color: var(--color-dark);
}

@media screen and (min-width: 800px) {
  .navbar {
    flex-direction: row;
  }
  
  .navbar__logo {
    margin-right: auto;
  }
  
  .navbar__menu {
    font-size: 1.4rem;
  } 
}

.nav-link {
  color: var(--color-accent);
  padding: 0.2rem 0.4rem;
  transition: ease-in-out 0.2s;
}
.nav-link--active {
  color: var(--color-light);
  background-color: var(--color-accent);
}