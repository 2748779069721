:root{
  --color-light: #F5F7FB;
  --color-dark: black;
  --color-accent: #4D5B9E;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: var(--color-light);
}

h1,
h2,
h3 {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
}

.app {
  padding-bottom: 6rem;
  max-width: 1000px;
  margin: 0 auto;
}

.btn {
  border: 0;
  background-color: var(--color-accent);
  color: var(--color-light);
  font-weight: bold;
  border-radius: 7px;
  padding: 0.5rem; 
  border: 1px solid transparent;
}
.btn:hover {
  transform: scale(1.05);
  transition: ease-in-out 0.1s;
}
.btn--inverse {
  background-color: var(--color-light);
  color: var(--color-accent);
  border: 1px solid var(--color-accent);
}

.input-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  width: 50%;
  min-width: 200px;
  max-width: 300px;  
}
.input-form__row {
  display: flex;
  justify-content: center;
  gap: 0.6rem;
  width: 100%;
}
.input-form__input {
  border: 0;
  padding: 0.5rem; 
  border-radius: 7px;
  width: 100%;
  text-align: center;
  justify-content: center;
}
.input-form__select {
  border: 0;
  padding: 0.5rem; 
  border-radius: 7px;
  width: 100%;
  text-align: center;
}
.input-form__input--inline {
  border-radius: 0;
  text-align: left;
  background-color: transparent;
  padding: 0;
  margin: calc(0.5rem - 2px) 0;
  font-size: 1rem;
  margin-right: auto;
  vertical-align: middle;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1.5rem;
}

.list {
  margin: 0;
  padding: 0;
  width: 100%;
}

.list-item {
  padding: 0 0.5rem;
}
.list-item:nth-of-type(2n+1) {
  background-color: white;
}

.button-icon {
  padding: 0.3rem;
  margin: 0 0.3rem;
  display: none;
}
.button-icon:hover {
  transform: scale(1.3);
  transition: ease-in-out 0.1s;
  cursor: pointer;
}
.list-item:hover .button-icon{
  display: inline-block;
}
.button-icon--not-hidden {
  display: inline-block;
}